import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import {
  SanityBlock,
  SanityCarouselButton,
  SanityCarouselProductBuyButton,
  SanityCarouselProductLinkButton,
  SanitySnipcartVariant,
} from "../../../graphql-types";
import * as styles from "./homeCarouselBlockContent.module.scss";
import { usePageContext } from "../Context/pageContext";
import { Link } from "gatsby";
import { AddItemButton } from "../Snipcart/AddItemButton";
import { SanityProduct, SanitySnipcartProduct } from "../../../graphql-types";

interface Props {
  blocks: SanityBlock;
}

export const HomeCarouselBlockContent: React.FC<Props> = ({ blocks }) => {
  const { lang, defaultLang } = usePageContext();
  const [snip, setSnip] = useState<any>(undefined);

  useEffect(() => {
    const { Snipcart } = window;
    if (!Snipcart) return;
    setSnip(Snipcart);
  }, []);

  const serializers = {
    types: {
      carouselProductLinkButton: (props: any) => {
        const button: SanityCarouselProductLinkButton = props.node;
        const link = `${
          lang !== defaultLang
            ? `/${lang}/products/${button?.product?.slug?.current}`
            : `/products/${button?.product?.slug?.current}`
        }`;
        return (
          <Link to={link} className={styles.button}>
            {button.content}
          </Link>
        );
      },
      carouselProductBuyButton: (props: any) => {
        const button: SanityCarouselProductBuyButton = props.node;
        const product: SanitySnipcartProduct = button.product as any;
        return (
          <AddItemButton
            // className={styles.button}
            variant={product?.defaultVariant as SanitySnipcartVariant}
            slug={product?.slug?.current || ""}
            excerpt={product?.description?.[lang] || ""}
            title={product?.snipcartTitle}
            // tva={0}
            quantity={1}
            image={
              product?.defaultVariant?.images?.[0]?.image?.asset?.url || ""
            }
            onClick={() => {
              if (snip) snip.api.theme.cart.open();
            }}
            buttonTitle={button?.content}
          />
        );
      },
    },
  };
  return (
    <div className={styles.wrapper}>
      {blocks && (
        <BlockContent
          blocks={blocks}
          projectId={process.env.GATSBY_SANITY_PROJECT_ID || ""}
          dataset={process.env.GATSBY_SANITY_DATASET || "production"}
          serializers={serializers}
          className={styles.column}
        />
      )}
    </div>
  );
};
