import React, { useEffect, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import * as styles from "./homeCarouselSlides.module.scss";
import { graphql, useStaticQuery } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import { HomeCarouselBlockContent } from "../../components/BlockContent/HomeCarouselBlockContent";
import { usePageContext } from "../../components/Context/pageContext";
import Swiper from "swiper";
import ReactPlayer from "react-player/youtube";
import { SanityCarouselItem } from "../../../graphql-types";

interface Props {
  item: SanityCarouselItem;
  index: number;
  slideClass?: string;
  swiper: Swiper | undefined;
}

const getAutoVideoIfAvailable = (video: any) =>
  video?.derived.find((derived: any) => derived.raw_transformation === "q_auto")
    ? video?.derived.find(
        (derived: any) => derived.raw_transformation === "q_auto"
      ).secure_url
    : video?.secure_url;

export const HomeCarouselSlide: React.FC<Props> = ({ item, swiper, index }) => {
  const { lang } = usePageContext();
  const { size } = usePageContext();
  const refMobile = useRef<ReactPlayer>(null);
  const refDesktop = useRef<ReactPlayer>(null);

  console.log("item", item);

  useEffect(() => {
    if (item._type === "image") {
      const time = setTimeout(() => {
        if (
          swiper?.slides?.length &&
          swiper?.activeIndex === swiper?.slides?.length - 1
        )
          swiper?.slidePrev();
        else swiper?.slideNext();
      }, 10000);
      swiper?.on("slideChange", () => {
        clearTimeout(time);
      });
      return () => clearTimeout(time);
    }
  }, [swiper]);

  // useEffect(() => {
  //   if (refMobile && refMobile.current) refMobile.current.props. = true;
  // }, []);

  // swiper?.on("slideChange", () => {
  //   if (swiper?.activeIndex !== index) {
  //     refMobile?.current?.pause();
  //     refDesktop?.current?.pause();
  //   }
  //   if (swiper?.activeIndex === index) {
  //     refDesktop?.current?.play();
  //     refMobile?.current?.play();
  //   }
  // });

  console.log("item", item);
  return (
    <div className={styles.slideWrapper}>
      <div className={styles.backgroundImage}>
        {item.bgType === "image" ? (
          <div className={styles.imgWrapper}>
            <GatsbyImage
              image={item.bgImageMobile?.asset?.gatsbyImageData}
              alt={"concave"}
              className={`${styles.img} ${styles.mobile}`}
            />
            <GatsbyImage
              image={item.bgImageMobile?.asset?.gatsbyImageData}
              alt={"concave"}
              className={`${styles.img} ${styles.desktop}`}
            />
          </div>
        ) : (
          <div className={`${styles.videoWrapper} ${styles.imgWrapper}`}>
            <div className={styles.playerWrapper}>
              <ReactPlayer
                width="100%"
                height="100%"
                url={item.bgVideoYt ?? ""}
                muted
                playing={true}
                loop
                playsinline
                onEnded={() => {
                  if (
                    swiper?.slides?.length &&
                    swiper?.activeIndex === swiper?.slides?.length - 1
                  )
                    swiper?.slidePrev();
                  else swiper?.slideNext();
                }}
                className={styles.reactPlayer}
                config={{
                  playerVars: {
                    showinfo: 0,
                    controls: 0,
                    autoplay: 1,
                    loop: 0,
                    modestbranding: 1,
                    disablekb: 1,
                  },
                }}
                type="video/mp4"
                // poster={`${item?.bgVideo?.secure_url?.slice(0, -4)}.jpg`}
              />
            </div>
            {/* <video
              className={`${styles.img} ${styles.mobile}`}
              muted
              ref={refMobile}
              loop
              playsInline
              onEnded={() => {
                if (
                  swiper?.slides?.length &&
                  swiper?.activeIndex === swiper?.slides?.length - 1
                )
                  swiper?.slidePrev();
                else swiper?.slideNext();
              }}
            >
              <source
                src={item.bgVideoMobile?.secure_url}
                poster={`${item?.bgVideo?.secure_url?.slice(0, -4)}.jpg`}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video> */}

            {/* <video
                className={`${styles.img} ${styles.desktop}`}
              muted
              ref={refDesktop}
              playsInline
              onEnded={() => {
                console.log("video ended");
                if (
                  swiper?.slides?.length &&
                  swiper?.activeIndex === swiper?.slides?.length - 1
                )
                  swiper?.slidePrev();
                else swiper?.slideNext();
              }}
            >
              <source
                src={getAutoVideoIfAvailable(item?.bgVideo)}
                poster={`${item?.bgVideo?.secure_url?.slice(0, -4)}.jpg`}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video> */}
          </div>
        )}
      </div>
      <div className={styles.slideWrapper}>
        <div className={styles.imageContainer}></div>
        <div className={styles.content}>
          <HomeCarouselBlockContent blocks={item._rawContent?.[lang]} />
        </div>
      </div>
    </div>
  );
};
