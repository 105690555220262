import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { HomeQuoteQuery } from "../../../graphql-types";
import * as styles from "./secondScreenHome.module.scss";
import { HomeBlockContent } from "../../components/BlockContent/HomeBlockContent";
import { usePageContext } from "../../components/Context/pageContext";
import { useInView } from "react-intersection-observer";
import { GatsbyImage } from "gatsby-plugin-image";
import { PureWrapper } from "../../utils/PureWrapper";

export const SecondScreenHome = React.memo(() => {
  const { page } = useStaticQuery<HomeQuoteQuery>(HOMEPAGEQUERY);
  const { lang } = usePageContext();
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const { ref: refText, inView: inViewText } = useInView({
    threshold: 0.9,
    triggerOnce: true,
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.section} ref={ref}>
          {page?.content?.secondScreenType === "video" ? (
            <video
              className={`${styles.video} ${inView && styles.fade}`}
              autoPlay
              muted
              loop
            >
              <source
                src={page?.content?.quoteVideo?.asset?.url || ""}
                type="video/mp4"
              />
              {/* <source src="/media/cc0-videos/flower.mp4" type="video/mp4" /> */}
              Sorry, your browser doesn't support embedded videos.
            </video>
          ) : (
            <GatsbyImage
              image={page?.content?.quoteImage?.asset?.gatsbyImageData}
              alt={"concave"}
              className={`${styles.img} ${inView && styles.fade}`}
              imgClassName={styles.image}
            />
          )}
          <div
            ref={refText}
            className={`${styles.text} ${inViewText && styles.translate}`}
          >
            <HomeBlockContent blocks={page?.content?._rawQuote?.[lang]} />
          </div>
        </div>
      </div>
    </>
  );
});

const HOMEPAGEQUERY = graphql`
  query HomeQuote {
    page: sanityHomePage {
      content {
        secondScreenType
        _rawQuote
        quoteImage {
          asset {
            gatsbyImageData
          }
        }
        quoteVideo {
          asset {
            url
          }
        }
      }
    }
  }
`;
