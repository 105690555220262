import React, { useContext } from "react";
import { Layout } from "../components/Layout/Layout";
import { SEO } from "../components/Seo";
import { FirstScreenHome } from "../modules/Home/FirstScreenHome";
import { SecondScreenHome } from "../modules/Home/SecondScreenHome";
import { HomeProduct } from "../modules/Home/HomeProduct";

const IndexPage: React.FC = () => {
  return (
    <Layout noBackbutton={true}>
      <SEO />
      <FirstScreenHome />
      <SecondScreenHome />
      <HomeProduct />
    </Layout>
  );
};

export default IndexPage;
