import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { SanityBlock } from "../../../graphql-types";
import * as styles from "./homeBlockContent.module.scss";

interface Props {
  blocks: SanityBlock;
}

export const HomeBlockContent: React.FC<Props> = ({ blocks }) => {
  return (
    <>
      {blocks && (
        <div className={styles.wrapper}>
          <BlockContent blocks={blocks} />
        </div>
      )}
    </>
  );
};
