import React from "react";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import * as styles from "./homeProduct.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { HomePageQuery } from "../../../graphql-types";
import { usePageContext } from "../../components/Context/pageContext";
import { ProductPreview } from "../../components/Product/ProductPreview";

export const HomeProduct = React.memo(() => {
  const { page } = useStaticQuery<HomePageQuery>(query);
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      <PageTitle content={page?.content?._rawStarProductsText?.[lang]} />
      <div className={styles.mosaic}>
        {page?.content?.starProducts?.map((e: any, index: number) => {
          return (
            <ProductPreview
              key={`product-preview${index}`}
              image={e?.images?.[0]?.image?.asset?.gatsbyImageData}
              alt={e?.images?.[0]?._rawAlt?.[lang] || "concave"}
              slug={e?.slug?.current}
              title={e?._rawTitle?.[lang]}
              price={e?.defaultVariant?.price}
            />
          );
        })}
      </div>
    </div>
  );
});

const query = graphql`
  query HomeProduct {
    page: sanityHomePage {
      content {
        _rawStarProductsText
        starProducts {
          slug {
            current
          }
          images {
            image {
              asset {
                gatsbyImageData
              }
            }
            _rawAlt
          }
          _rawTitle
          defaultVariant {
            price
          }
          variants {
            price
          }
        }
      }
    }
  }
`;
