// extracted by mini-css-extract-plugin
export var firstScreen = "firstScreenHome-module--firstScreen--aMy6v";
export var firstScreenVideo = "firstScreenHome-module--firstScreenVideo--2g8TY";
export var bottomWrapper = "firstScreenHome-module--bottomWrapper--JBFF6";
export var blackTopGradient = "firstScreenHome-module--blackTopGradient--1v0ky";
export var blackBottomGradientDesktopVideo = "firstScreenHome-module--blackBottomGradientDesktopVideo--3jhLq";
export var blackBottomGradientDesktop = "firstScreenHome-module--blackBottomGradientDesktop--2qJ0X";
export var socialsWrapper = "firstScreenHome-module--socialsWrapper--3k0nd";
export var bottomContainer = "firstScreenHome-module--bottomContainer--2v784";
export var slideContainer = "firstScreenHome-module--slideContainer--2PRVz";
export var swiperContainer = "firstScreenHome-module--swiperContainer--2Yn76";