// extracted by mini-css-extract-plugin
export var slideWrapper = "homeCarouselSlides-module--slideWrapper--2vAcR";
export var imageContainer = "homeCarouselSlides-module--imageContainer--bYU0s";
export var content = "homeCarouselSlides-module--content--1zNnG";
export var button = "homeCarouselSlides-module--button--1o_Mv";
export var backgroundImage = "homeCarouselSlides-module--backgroundImage--1Zej-";
export var imgWrapper = "homeCarouselSlides-module--imgWrapper--1wdlz";
export var videoWrapper = "homeCarouselSlides-module--videoWrapper--2gC15";
export var img = "homeCarouselSlides-module--img--3tJwd";
export var blackBottomGradientMobile = "homeCarouselSlides-module--blackBottomGradientMobile--prA5J";
export var blackTopGradient = "homeCarouselSlides-module--blackTopGradient--2cXzp";
export var mobile = "homeCarouselSlides-module--mobile--rGjPE";
export var desktop = "homeCarouselSlides-module--desktop--1_vzN";
export var playerWrapper = "homeCarouselSlides-module--playerWrapper--2T6pS";
export var reactPlayer = "homeCarouselSlides-module--reactPlayer--3nUNn";