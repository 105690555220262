import React, { useState } from "react";
import Swiper from "swiper";
import { Carousel } from "../../components/Carousel/Carousel";
import { CarouselPagination } from "../../components/Carousel/CarouselPagination";
import { HomeCarouselSlide } from "./HomeCarouselSlide";
import { Socials, Theme } from "../../components/Socials/Socials";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "./firstScreenHome.module.scss";
import { SwiperSlide } from "swiper/react";
import { HomePageQuery } from "../../../graphql-types";

export const FirstScreenHome = React.memo(() => {
  const [swiper, setSwiper] = useState<Swiper>();
  const { page } = useStaticQuery<HomePageQuery>(HOMEPAGEQUERY);

  console.log("page", page);

  return (
    <>
      {/* <div className={styles.blackTopGradient} /> */}
      <div className={styles.firstScreenVideo}>
        <Carousel
          setSwiper={setSwiper}
          swiperContainerClass={styles.swiperContainer}
        >
          {page?.content?.carouselItems?.length &&
            page?.content?.carouselItems?.map((item: any, index: number) => (
              <SwiperSlide
                key={`slide-${item._key}`}
                // className={styles.slideClass || styles.slide}
              >
                <HomeCarouselSlide item={item} swiper={swiper} index={index} />
              </SwiperSlide>
            ))}
        </Carousel>
      </div>
      <div className={styles.blackBottomGradientDesktopVideo} />
      <div className={styles.bottomWrapper}>
        <div className={styles.bottomContainer}>
          <CarouselPagination swiper={swiper} spacing={15} />
          <div className={styles.socialsWrapper}>
            <Socials theme={Theme.white} />
          </div>
        </div>
      </div>
    </>
  );
});

const HOMEPAGEQUERY = graphql`
  query HomePage {
    page: sanityHomePage {
      content {
        quoteVideo {
          asset {
            url
          }
        }
        starProducts {
          slug {
            current
          }
          _rawSlug
          _rawStarOnlies(resolveReferences: { maxDepth: 5 })
          _rawStarAbouts(resolveReferences: { maxDepth: 5 })
        }
        quote {
          _key
          _type
          _rawFr
          _rawEn
        }
        _rawQuote
        _rawStarProductsText
        carouselItems {
          _key
          _type
          bgType
          bgVideoYt
          bgImage {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
          bgImageMobile {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
          # bgVideo {
          #   url
          #   secure_url
          #   format
          #   duration
          #   derived {
          #     raw_transformation
          #     secure_url
          #   }
          # }
          # bgVideoMobile {
          #   url
          #   secure_url
          #   format
          #   duration
          #   derived {
          #     raw_transformation
          #     secure_url
          #   }
          # }
          # image {
          #   asset {
          #     gatsbyImageData(placeholder: NONE)
          #   }
          # }
          _rawContent(resolveReferences: { maxDepth: 8 })
        }
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }
  }
`;
